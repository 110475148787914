import React, {useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import AddressAddModal from '../Modals/AddressAddModal'
import ReactToPrint from 'react-to-print'
import Barcode from 'react-barcode'
import {
  Box,  
  Input,
  Stack,
  Button,
  FormControl,
  FormLabel,
  Divider,
  Text,
  Heading,
  HStack,
  Spacer,
  useToast,
  Select,
  IconButton,
  UnorderedList,
  ListItem,
  Checkbox,
  SimpleGrid,
  GridItem,
  useBreakpointValue,
  Wrap
} from '@chakra-ui/react'
import { shipTypes } from '../data/shipTypes'
// import { MdSearch } from 'react-icons/md'
import { ADDRESS_CREATE_RESET } from '../constants/addressConstants'
import { createPreshipment } from '../actions/shipmentActions'
import { USER_BYID_RESET } from '../constants/userConstants'
import { MdLocalPrintshop, MdDeleteForever } from 'react-icons/md'
import { PRESHIPMENT_CREATE_RESET, SHIPMENT_GET_COST_RESET } from '../constants/shipmentConstants'
import AddressSearchModal from '../Modals/AddressSearchModal'
import AgentSearchModal from '../Modals/AgentSearchModal'
import { boxSizes } from '../data/boxSizes'
import { getCostShipment } from '../actions/shipmentActions'
import { getCostTHPShipment } from '../actions/thailand_post_actions/thpShipmentActions'
import Error from '../components/Error'
import { THPSHIPMENT_GETCOST_RESET } from '../constants/thailand_post_constants/thpShipmentConstants'
import { getChargesList } from '../actions/chargesActions'
import MySpinner from '../components/MySpinner'
import { getDHLAccountList } from '../actions/dhlAccountActions'


const ShipmentPreCreateScreenNew = () => {
  const colSpan = useBreakpointValue({base:2, md:1})
  const componentRef = useRef()
  const dispatch = useDispatch()
  const toast = useToast()
  const navigate = useNavigate()
  const [agSelected, setAgSelected] = useState({})
  const [shipType, setShipType] = useState('dhl')
  const [charges, setCharges] = useState([])
  const [over160L, setOver160L] = useState(false)
  const [over160W, setOver160W] = useState(false)
  const [over160H, setOver160H] = useState(false)
  const [overWeight, setOverWeight] = useState(false)
  const [isVplus, setIsVplus] = useState(false)
  const [isSpecialRoute, setIsSpecialRoute] = useState(false)
  const [newChargeName, setNewChargeName] = useState('')
  const [newChargeAmount, setNewChargeAmount] = useState('')
  const [shipper, setShipper] = useState('')
  const [dhlShipperAccount, setDhlShipperAccount] = useState('')
  const [dhlPayerAccount, setDhlPayerAccount] = useState('')
  const [receiverAddress, setReceiverAddress] = useState({})
  const [payment, setPayment] = useState(0)
  const [boxes, setBoxes] = useState([
    {
      weight: 0,
      dimensions: {
          length: 0,
          width: 0,
          height: 0,
      }
    }
  ])
  const [note, setNote] = useState('')
  const [productCode, setProductCode] = useState('P')
  const {loading: loadingCharges, charges: chargesList, error: errorCharges } = useSelector(state=>state.charges.List)
  const {loading: loadingCreateAddress, error: errorCreateAddress, success: successCreateAddress, newAddress} = useSelector(state=>state.address.Create)
  const {loading: loadingCreatePre, error: errorCreatePre, success: successCreatePre, preShipment} = useSelector(state=>state.preshipment.Create)
  const {loading: loadingGetCost, error: errorGetCost, cost: shipmentCost} = useSelector(state=>state.shipment.GetCost)
  const {loading: loadingTHPCost, error: errorTHPCost, cost: costTHP} = useSelector(state=>state.thailandPost.ShipmentGetCost)
  const {loading: loadingDHLAcc, accounts: dhlAccounts, error:errorDHLAcc } = useSelector(state=>state.dhlAccount.List)

  
  const pageStyle = `
    @page {
        size: 3.2in 3.9in;
        margin: 2mm 2mm;
    }
    
    `;
    // html, body {
    //     font-size: 13px;
    //     font-weight: 400;
    // }

  useEffect(()=>{    
    if(successCreatePre){
      setPayment(0)
      setNote('')
      dispatch({type: USER_BYID_RESET})
      dispatch({type: ADDRESS_CREATE_RESET})
    }
    if(successCreateAddress){
      setReceiverAddress(newAddress)
    }
    dispatch(getChargesList())
    dispatch(getDHLAccountList())
    return()=>{
      dispatch({type: THPSHIPMENT_GETCOST_RESET})
      dispatch({type: SHIPMENT_GET_COST_RESET})
    }
  },[ successCreatePre, dispatch, successCreateAddress, newAddress ])


  const handleClearForm = () => {
      dispatch({type: USER_BYID_RESET})
      dispatch({type: ADDRESS_CREATE_RESET})
      dispatch({type: PRESHIPMENT_CREATE_RESET})
      window.location.reload(false)
  }

  const handleGoBack = () => {
    dispatch({type: USER_BYID_RESET})
    dispatch({type: ADDRESS_CREATE_RESET})
    dispatch({type: PRESHIPMENT_CREATE_RESET})
    navigate('/preshipment')
  }

  const handleAddCharges = () => {
    if(newChargeName === '' || newChargeName === 'Select'){return}
    if(newChargeAmount === '' || newChargeAmount === 0){return}
    const existIndex = charges.map(c => c.name).indexOf(newChargeName)
    if(existIndex !== -1){return}
    const newChargeToPush = {
        name: newChargeName,
        amount: Number(newChargeAmount)
    }
    setNewChargeName('')
    setNewChargeAmount('')
    setCharges([...charges, newChargeToPush])
}
const handleRemoveCharges = (idx) => {
    const newCharges = charges
    //const existIndex = newCharges.map(c => c.name).indexOf(chargeName)
    newCharges.splice(idx, 1)
    setCharges([...newCharges])
}

  const handleGetCost = () => {
    if(shipType === 'dhl'){
      if(receiverAddress.countrycode === 'TH'){
        toast({
            status: 'error',
            position: 'top',
            title: 'Receiver country can not be Thailand',
            duration: 4000,
            isClosable: true,
        }) 
        return
      }
      const totalWeight = boxes.reduce((acc, box) => acc + Number(box.weight), 0)
      const data = {
          agentId: Object.keys(agSelected).length !== 0 ? agSelected._id : null,
          totalWeight: Number(totalWeight),
          productCode,
          countryCode: receiverAddress.countrycode
      }
      if(data.totalWeight === 0 || data.agentId === null){
          toast({
              status: 'error',
              position: 'top',
              title: 'Please recheck box weight, agent is selected and address is selected',
              duration: 4000,
              isClosable: true,
          }) 
          return
      }
      if(data.productCode === 'D' && data.totalWeight > 2){
          toast({
              status: 'error',
              position: 'top',
              title: 'Document weight can not be more than 2 kg',
              duration: 4000,
              isClosable: true,
          }) 
          return
      } else {
          //console.log(data)
          dispatch(getCostShipment(data))
      }
    } else {
      const totalWeight = Math.floor(boxes.reduce((acc, box) => acc + Number(box.weight), 0))
      if(Object.keys(receiverAddress).length === 0 || totalWeight === 0){
        toast({
          status: 'warning',
          position: 'top',
          title: 'Receiver address and box weight are required.',
          duration: 4000,
          isClosable: true,
      }) 
      return
      }
      const data = {
        agentId: Object.keys(agSelected).length !== 0 ? agSelected._id : null,
        country: receiverAddress.country,
        totalWeight,
        shipType,
      }
      dispatch(getCostTHPShipment(data))
      //console.log(data)
    }
    
  }


  const handleAddNewBox = () => {
    const newBox = {
      weight: 0,
      dimensions: {
          length: 0,
          width: 0,
          height: 0,
      }
    }
    setBoxes([...boxes, newBox])
  }

  const handleRemoveBox = (i) => {
    const newBoxes = [...boxes]
    newBoxes.splice(i, 1)
    setBoxes([...newBoxes])
  }
  const handleBoxSelect = (bi, i) => {
    const newBoxes = [...boxes]
    newBoxes[i].dimensions.length = boxSizes[bi].dimensions.length
    newBoxes[i].dimensions.width = boxSizes[bi].dimensions.width
    newBoxes[i].dimensions.height = boxSizes[bi].dimensions.height
    setBoxes([...newBoxes])
  }

  const handleBoxSize = (v, name, i) => {
    //console.log(v, name, i)
    const newBoxes = [...boxes]
    newBoxes[i].dimensions[name] = v
    setBoxes([...newBoxes])
  }
  
  const handleBoxWeight = (v, i) => {
    //console.log(v, i)
    const newBoxes = [...boxes]
    newBoxes[i].weight = v
    setBoxes([...newBoxes])
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if(over160H || over160L || over160W){
      const index = charges.findIndex(x=>x.name === 'Over Size')
      if(index === -1){
        toast({
            status: 'error',
            position: 'top',
            title: 'Over size charges not added',
            duration: 4000,
            isClosable: true,
        }) 
        return
      }
    }
    if(overWeight){
      const index = charges.findIndex(x=>x.name === 'Over Weight')
      if(index === -1){
        toast({
            status: 'error',
            position: 'top',
            title: 'Over weight charges not added',
            duration: 4000,
            isClosable: true,
        }) 
        return
      }
    }
    if(Object.keys(agSelected).length === 0 || Object.keys(receiverAddress).length === 0){
      toast({
        status: 'warning',
        position: 'top',
        title: 'Please complete the agent and receiver detail.',
        duration: 5000,
        isClosable: true,
      }) 
      return
    }
    const newPreShipment = {
      agentId: agSelected._id,
      addressId: receiverAddress._id,
      paidAmount: payment,
      boxes,
      charges,
      shipType: shipType === '' ? 'dhl' : shipType,
      productCode,
      note,
      isVplus,
      isSpecialRoute,
      shipper,
      dhlShipperAccount,
      dhlPayerAccount,
    }
    dispatch(createPreshipment(newPreShipment))
  }

  return (
    <Box my='5'>
      <HStack>
        <Heading mb='3' fontWeight={'700'} borderBottom='1px'>Create Pre Shipment </Heading>  
        {/* {(loadingCharges || loadingCreateAddress || loadingCreatePre) && <MySpinner />} */}
        {loadingCharges && <div><MySpinner /> loading charges...</div>}
        {loadingCreateAddress && <div><MySpinner /> loading address...</div>}
        {loadingCreatePre && <div><MySpinner /> saving preshipment...</div>}
        {loadingDHLAcc && <div><MySpinner /> getting dhl accounts preshipment...</div>}
      </HStack>
        {errorCreatePre && <Error error={errorCreatePre} />}
        {errorGetCost && <Error error={errorGetCost} /> }
        {errorTHPCost && <Error error={errorTHPCost} /> }
        {errorCharges && <Error error={errorCharges} /> }
        {errorDHLAcc && <Error error={errorDHLAcc} /> }
        {errorCreateAddress && <Error error={errorCreateAddress} /> }


        {successCreatePre ?
              <Box>
                <HStack my='3'>
                  <Button size='sm' onClick={handleGoBack}>Go Back to List</Button> 
                  <Spacer />             
                  <Button size='sm' colorScheme={'orange'} onClick={()=>window.location.reload(false)}>Create new</Button>
                    
                  <Spacer />
                  <Box>
                    <ReactToPrint
                      trigger={() => <Button size='sm' w='100px' colorScheme='green' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                      content={() => componentRef.current}
                      pageStyle={()=>pageStyle}
                    />
                  </Box>
                </HStack>
                
                <Box h='30px'></Box>
                <Box w='320px' mx='auto' p={'2'} >
                  <Box ref={componentRef} >
                    {preShipment && preShipment.boxes.map((b,i)=>(
                      <Box key={i} w={'302px'} h={'370px'} border={'1px'} borderColor={'gray.500'} borderRadius={5} mb={1}>
                        <Text fontSize='2rem' pt='5px' lineHeight={'23px'}>{preShipment.code}</Text>
                        <Box ps={2}>
                          <Barcode value={preShipment.code} margin={0} height={20} width={2} displayValue={false} />
                        </Box>
                        <Box px='2' lineHeight={'20px'} fontWeight={600} className='page-break'>
                          <Text>{preShipment.agentId.sale.addressId.name}</Text>
                          <Text>A - {preShipment.agentId.name} - {preShipment.agentId.agentCode}</Text>
                          <Text>S - {preShipment.agentId.sale.name} - {preShipment.agentId.sale.saleCode}</Text>

                          <Box w='100%' borderBottom={'1px'}></Box>

                          <Box>
                            <Text>{preShipment.addressId.name}</Text>
                            <Text>{preShipment.addressId.address}</Text>
                            {preShipment.addressId.address2 && <Text>{preShipment.addressId.address2}</Text>}
                            {preShipment.addressId.address3 && <Text>{preShipment.addressId.address3}</Text>}
                            <Text>{preShipment.addressId.city} {preShipment.addressId.country} {preShipment.addressId.postalcode}</Text>
                            <Text>{preShipment.addressId.phone}</Text>
                          </Box>

                          <HStack w='100%' borderY={'1px'}>
                            <Text>{preShipment.createdAt.substring(0,10)}</Text>
                            <Spacer />
                            <Text>{preShipment.shipType && preShipment.shipType.toUpperCase()}</Text>
                          </HStack>

                          <HStack>
                            <Text>{i+1} of {preShipment.boxes.length}</Text>
                            <Spacer />
                            <Text>{b.dimensions.length}x{b.dimensions.width}x{b.dimensions.height}</Text>
                            <Spacer />
                            <Text>{b.weight} kg</Text>                                            
                          </HStack>

                          <Box w='100%' borderBottom={'1px'}></Box>
                          <HStack fontWeight={'900'}>
                            {preShipment.charges && preShipment.charges.map((c,i)=>(
                              <Box key={i}>{c.name}, </Box>
                            ))}
                          </HStack>
                          <Box w='100%' borderBottom={'1px'}></Box>
                          <Text fontWeight='800' fontSize='sm'>{preShipment.note}</Text>
                          <Text color='red' fontWeight={'800'} as='samp' >Warning !!! estimated data !!</Text>
                        </Box>
                      </Box>  
                    ))}
                  </Box> 
                </Box>                          
              </Box>
              :
              <>

              
        <SimpleGrid columns={2} columnGap={3} rowGap={3}>
          <GridItem>
            <Box mb={3} w={{base:'100%', sm:'100%', md:'50%'}}>
                <Text>Shipper</Text>
                <Input 
                  id={'shipper'}
                  type={'text'}
                  size='sm'
                  autoFocus
                  border='1px'
                  borderColor="gray.600"
                  value={shipper}
                  onChange={({target})=>{
                    setShipper(target.value)
                  }}
                />
              </Box>
          </GridItem>
          <GridItem>
              <Box display={'flex'} justifyContent={'space-between'} gap={3} p={2} border='1px' borderColor={'gray.300'}>
                  <Stack spacing={'0'} flex={1}>
                      <FormLabel>Shipper Account</FormLabel>
                      <Select placeholder='Select' size='sm' onChange={(e)=>setDhlShipperAccount(e.target.value)} >
                          {dhlAccounts && dhlAccounts.map((acc, idx)=>(
                              <option key={idx} value={acc.account}>{acc.account} - {acc.name}</option>
                          ))}
                      </Select>
                  </Stack>

                  <Stack spacing={'0'} flex={1}>
                      <FormLabel>Payer Account</FormLabel>
                      <Select placeholder='Select' size='sm' onChange={(e)=>setDhlPayerAccount(e.target.value)} >
                          {dhlAccounts && dhlAccounts.map((acc, idx)=>(
                              <option key={idx} value={acc.account}>{acc.account} - {acc.name}</option>
                          ))}
                      </Select>
                  </Stack>

              </Box>
            </GridItem>
            <GridItem colSpan={colSpan}>
                <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                    <HStack mb='3' pb='3' fontWeight={'700'} borderBottom='1px' justifyContent={'space-between'} >
                        <Text>Agent</Text>
                        <AgentSearchModal agentSelected={(value) => setAgSelected(value)} />
                    </HStack>
                    <Text bg={'yellow.100'} p={'2'} borderRadius={'5%'}>{agSelected.agentCode}  {agSelected.name}</Text>
                </Box>
            </GridItem>


            
            <GridItem colSpan={colSpan}>
                <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                    <HStack mb='3' pb='3' fontWeight={'700'} borderBottom='1px' justifyContent={'space-between'}>
                        <Text>Receiver</Text>
                        <AddressSearchModal addressSelected={(value) => setReceiverAddress(value)} />
                        <AddressAddModal />   
                    </HStack>
                    {
                        receiverAddress &&
                        <Box bg={'yellow.100'} p={'2'} borderRadius={'5%'}>
                        <Text>{receiverAddress.name}</Text>
                        <Text>{receiverAddress.company}</Text>
                        <Text>{receiverAddress.phone}</Text>
                        <Text>{receiverAddress.address}</Text>
                        {receiverAddress.address2 && <Text>{receiverAddress.address2}</Text>}
                        {receiverAddress.address3 && <Text>{receiverAddress.address3}</Text>}
                        <Text>{receiverAddress.city} {receiverAddress.country} {receiverAddress.postalcode}</Text>
                        </Box>
                    }
                </Box>
            </GridItem>

            <GridItem colSpan={colSpan}>
                <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                    <FormLabel fontSize='xl'>Ship Type <span style={{'fontSize' : '10px'}}>(default: DHL)</span></FormLabel>
                    <Select placeholder='DHL' size='sm' onChange={(e)=>setShipType(e.target.value)}>
                        {shipTypes.map((st, idx)=>(
                        <option key={idx} value={st.code}>{st.name}</option>
                        ))}
                    </Select>
                </Box>
            </GridItem>

            <GridItem colSpan={colSpan}>
                <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                    <FormLabel fontSize='xl'>Package/Document</FormLabel>
                    <Select placeholder='Select' size='sm' onChange={(e)=>setProductCode(e.target.value)}>
                        <option value='P'>Package</option>
                        <option value='D'>Document</option>
                    </Select>
                </Box>
            </GridItem>

            <GridItem colSpan={colSpan}>
                <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                    <FormLabel fontSize='xl'>Charges</FormLabel>
                    <HStack>
                        <Select placeholder='Select' size='sm' onChange={(e)=>setNewChargeName(e.target.value)} >
                            {chargesList && chargesList.map((c, idx)=>(
                                <option key={idx} value={c.name}>{c.name}</option>
                            ))}
                        </Select>
                        <Input 
                            type='number'
                            size='sm'
                            value={newChargeAmount}
                            onChange={(e)=>setNewChargeAmount(e.target.value)}
                            onKeyPress={e=>{
                                if(e.key === 'Enter'){
                                handleAddCharges()
                                }
                            }}
                        />
                        <Button
                            size='sm'
                            w='100px'
                            colorScheme={'green'}
                            onClick={handleAddCharges}
                        >Add</Button>
                    </HStack>
                    <Box bg='yellow.100' p='2' my='3'>
                        <UnorderedList spacing={3}>
                            {charges.map((ch, idx)=>(
                                <ListItem key={idx}>
                                    <HStack>
                                        <Box w='200px'>{ch.name}</Box>
                                        <Box>{ch.amount}</Box>
                                        <Spacer />
                                        <Button
                                            size='xs'
                                            colorScheme={'red'}
                                            onClick={()=>handleRemoveCharges(idx)}
                                        >
                                            x
                                        </Button>
                                    </HStack>
                                </ListItem>
                            ))}
                        </UnorderedList>
                    </Box>
                </Box>
            </GridItem>

            <GridItem colSpan={colSpan}>
                <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                <FormControl mb='2'>
                <FormLabel fontSize='xl' htmlFor='payment'>Payment <span style={{'color': 'red', 'fontSize': '13px'}}>thb</span></FormLabel>               
                    <Input 
                      size='sm' 
                      id='payment' 
                      type='number' 
                      value={payment}
                      onChange={(e)=>setPayment(e.target.value)}
                      />
                </FormControl>
              
                {shipmentCost && 
                    <Box w='100%' bg='yellow.100' p='2' >
                    <Text fontSize={'xl'}>                  
                        {shipmentCost + charges.reduce((acc, ch) => acc + ch.amount, 0)} thb
                    </Text>
                    <Text fontSize={'xs'}>Shipping cost - {shipmentCost}</Text>
                    <Text fontSize={'xs'}>Charges cost - {charges.reduce((acc, ch) => acc + ch.amount, 0)}</Text>
                    </Box>
                }

                {costTHP && 
                <Box w='100%' bg='yellow.100' p='2' >
                    <Text fontSize={'xl'}>                  
                    {costTHP + charges.reduce((acc, ch) => acc + ch.amount, 0)} thb
                    </Text>
                    <Text fontSize={'xs'}>Shipping cost - {costTHP}</Text>
                    <Text fontSize={'xs'}>Charges cost - {charges.reduce((acc, ch) => acc + ch.amount, 0)}</Text>
                </Box>
                }
                </Box>
            </GridItem>

            <GridItem colSpan={colSpan}>
                <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
               
                    <HStack>
                    <FormLabel fontSize='xl' htmlFor='weight'>Boxes <span style={{'color': 'red', 'fontSize': '13px'}}>cm, kg</span></FormLabel>               
                    <Spacer />
                    <Button
                        size='sm'
                        h='25px'
                        w='100px'
                        colorScheme={'green'}
                        onClick={handleAddNewBox}
                    >Add Box</Button>
                    </HStack>
                    {boxes.map((b,i)=>(
                        <Box key={i}>
                        {(over160L || over160W || over160H) && 
                        <Box bg='tomato' p={2} color='white'>
                          Add Charges for Oversize
                        </Box>
                        }
                        {overWeight && 
                        <Box bg='red' p={2} color='white'>
                          Add Charges for Overweight
                        </Box>
                        }
                        <HStack>
                            <Stack spacing={'0'} w='30px'>
                                <Text fontSize={'20px'}>{i+1}</Text>
                            </Stack>
                            <Stack spacing={'0'} w='150px'>
                                <Text fontSize={'sm'}>Box Size</Text>
                                <Select placeholder='Select Box Size' size='sm' onChange={(e)=>handleBoxSelect(e.target.value, i)}>
                                {boxSizes.map((bx, idx)=>(
                                    <option key={idx} value={idx}>{bx.name}</option>
                                ))}
                                </Select>
                            </Stack>
                            <Stack spacing={'0'} w='60px'>
                                <Text fontSize={'sm'}>Length</Text>
                                <Input 
                                size='sm'
                                value={b.dimensions.length}
                                onChange={(e)=>{
                                  if(Number(e.target.value)>100){
                                    setOver160L(true)
                                  } else {
                                    setOver160L(false)
                                  }
                                  handleBoxSize(e.target.value, 'length', i)}
                                }
                                />
                            </Stack>
                            <Stack spacing={'0'} w='60px'>
                                <Text fontSize={'sm'}>Width</Text>
                                <Input 
                                size='sm'
                                value={b.dimensions.width}
                                onChange={(e)=>{
                                  if(Number(e.target.value)>100){
                                    setOver160W(true)
                                  } else {
                                    setOver160W(false)
                                  }
                                  handleBoxSize(e.target.value, 'width', i)}
                                }
                                />
                            </Stack>
                            <Stack spacing={'0'} w='60px'>
                                <Text fontSize={'sm'}>Height</Text>
                                <Input 
                                size='sm'
                                value={b.dimensions.height}
                                onChange={(e)=>{
                                  if(Number(e.target.value)>100){
                                    setOver160H(true)
                                  } else {
                                    setOver160H(false)
                                  }
                                  handleBoxSize(e.target.value, 'height', i)}
                                }
                                />
                            </Stack>
                            <Spacer />
                            <Stack spacing={'0'} w='80px'>
                                <Text fontSize={'sm'}>Weight</Text>
                                <Input 
                                size='sm'
                                value={b.weight}
                                onChange={(e)=>{
                                  if(Number(e.target.value)>70){
                                    setOverWeight(true)
                                  } else {
                                    setOverWeight(false)
                                  }
                                  handleBoxWeight(e.target.value, i)}
                                }
                                />
                            </Stack>

                            <Stack spacing={'0'} pl='4'>
                            <Text fontSize={'sm'}>Remove</Text>
                            {/* <Button
                                size='sm'
                                colorScheme={'red'}
                                onClick={()=>handleRemoveBox(i)}
                            >
                                x
                            </Button> */}
                            <IconButton
                                fontSize='2xl'
                                width='10px'
                                colorScheme='red'
                                aria-label='remove box'
                                size='sm'
                                disabled={boxes.length === 1}
                                icon={<MdDeleteForever />}
                                onClick={()=>handleRemoveBox(i)}
                            />
                            </Stack>
                        </HStack>
                        <Box my='2'>
                            <Divider />
                        </Box>
                        </Box>
                    ))}
               
                </Box>
            </GridItem>

            <GridItem colSpan={colSpan}>
                <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                <FormControl>
                <FormLabel fontSize='xl' htmlFor='weight'>Note</FormLabel>               
                    <Input 
                        size='sm'
                        height={'100px'}
                        id='weight' 
                        as='textarea' 
                        value={note}
                        onChange={(e)=>setNote(e.target.value)}
                        />
                </FormControl>
                </Box>
            </GridItem>

            <GridItem colSpan={colSpan}>
                <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
            
                    <Checkbox
                        size='lg' 
                        colorScheme={'red'}
                        isChecked={isVplus}
                        onChange={(e)=>setIsVplus(e.target.checked)}
                    >This is VPlus</Checkbox>
                </Box>
                
            </GridItem>

            <GridItem colSpan={colSpan}>
                <Box bg='purple.200' p='3' borderRadius={10} h='full'>
            
                    <Checkbox
                        size='lg' 
                        colorScheme={'blue'}
                        isChecked={isSpecialRoute}
                        onChange={(e)=>{
                          setIsSpecialRoute(e.target.checked)
                        }}
                    >Special Route Shipment</Checkbox>
                </Box>
                
            </GridItem>

            
        </SimpleGrid>


        <Wrap justify='center' my='4'>
            <Link to='/preshipment'>
                <Button size='sm' w='150px' colorScheme={'blue'} >
                Cancel
                </Button>
            </Link>
            <Button size='sm' w='150px' colorScheme='purple' onClick={handleClearForm}>Clear Form</Button>

            <Button size='sm' w='150px' loading={loadingGetCost || loadingTHPCost} colorScheme='yellow' onClick={handleGetCost}>Get Cost</Button>
          
            <Button size='sm' w='150px' colorScheme={'green'} onClick={handleSubmit}>
                Submit
            </Button>
        </Wrap>
        </>
        }
    </Box>
  )}

  export default ShipmentPreCreateScreenNew